define("ember-cli-fastboot/services/fastboot", ["exports", "@ember/application/deprecations", "@ember/object", "@ember/object/computed", "@ember/debug", "@ember/service"], function (_exports, _deprecations, _object, _computed, _debug, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global FastBoot */

  const RequestObject = _object.default.extend({
    init() {
      this._super(...arguments);
      let request = this.request;
      delete this.request;
      this.method = request.method;
      this.body = request.body;
      this.cookies = request.cookies;
      this.headers = request.headers;
      this.queryParams = request.queryParams;
      this.path = request.path;
      this.protocol = request.protocol;
      this._host = function () {
        return request.host();
      };
    },
    host: (0, _object.computed)(function () {
      return this._host();
    })
  });
  const Shoebox = _object.default.extend({
    put(key, value) {
      (true && !(this.get('fastboot.isFastBoot')) && (0, _debug.assert)('shoebox.put is only invoked from the FastBoot rendered application', this.get('fastboot.isFastBoot')));
      (true && !(typeof key === 'string') && (0, _debug.assert)('the provided key is a string', typeof key === 'string'));
      let fastbootInfo = this.get('fastboot._fastbootInfo');
      if (!fastbootInfo.shoebox) {
        fastbootInfo.shoebox = {};
      }
      fastbootInfo.shoebox[key] = value;
    },
    retrieve(key) {
      if (this.get('fastboot.isFastBoot')) {
        let shoebox = this.get('fastboot._fastbootInfo.shoebox');
        if (!shoebox) {
          return;
        }
        return shoebox[key];
      }
      let shoeboxItem = this.get(key);
      if (shoeboxItem) {
        return shoeboxItem;
      }
      let el = document.querySelector(`#shoebox-${key}`);
      if (!el) {
        return;
      }
      let valueString = el.textContent;
      if (!valueString) {
        return;
      }
      shoeboxItem = JSON.parse(valueString);
      this.set(key, shoeboxItem);
      return shoeboxItem;
    }
  });
  const FastBootService = _service.default.extend({
    cookies: (0, _computed.deprecatingAlias)('request.cookies', {
      id: 'fastboot.cookies-to-request',
      until: '0.9.9'
    }),
    headers: (0, _computed.deprecatingAlias)('request.headers', {
      id: 'fastboot.headers-to-request',
      until: '0.9.9'
    }),
    isFastBoot: typeof FastBoot !== 'undefined',
    init() {
      this._super(...arguments);
      let shoebox = Shoebox.create({
        fastboot: this
      });
      this.set('shoebox', shoebox);
    },
    host: (0, _object.computed)(function () {
      (true && !(false) && (0, _deprecations.deprecate)('Usage of fastboot service\'s `host` property is deprecated.  Please use `request.host` instead.', false, {
        id: 'fastboot.host-to-request',
        until: '0.9.9'
      }));
      return this._fastbootInfo.request.host();
    }),
    response: (0, _computed.readOnly)('_fastbootInfo.response'),
    metadata: (0, _computed.readOnly)('_fastbootInfo.metadata'),
    request: (0, _object.computed)(function () {
      if (!this.isFastBoot) return null;
      return RequestObject.create({
        request: (0, _object.get)(this, '_fastbootInfo.request')
      });
    }),
    deferRendering(promise) {
      (true && !(typeof promise.then === 'function') && (0, _debug.assert)('deferRendering requires a promise or thennable object', typeof promise.then === 'function'));
      this._fastbootInfo.deferRendering(promise);
    }
  });
  var _default = _exports.default = FastBootService;
});