/* eslint-disable max-len, no-undef */

window.deprecationWorkflow = window.deprecationWorkflow || {};
window.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchMessage: /.*deprecated-run-loop-and-computed-dot-access.*/ },
    { handler: 'silence', matchMessage: /.*ember-global.*/ },
    { handler: 'silence', matchMessage: /.*ember\.built-in-components\.import.*/ },
    { handler: 'silence', matchMessage: /.*ember\.built-in-components\.legacy-arguments.*/ },
    { handler: 'silence', matchMessage: /.*ember\.built-in-components\.reopen.*/ },
    { handler: 'silence', matchMessage: /.*ember\.component\.reopen.*/ },
    { handler: 'silence', matchMessage: /.*implicit-injections.*/ },
    { handler: 'silence', matchMessage: /.*route-render-template.*/ },
  ],
};
