define("ember-cli-nouislider/templates/components/range-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "by0TXHhs",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-cli-nouislider/templates/components/range-slider.hbs",
    "isStrictMode": false
  });
});